<template>
  <section
    id="reskilling-banner"
    class="te-reskilling-banner"
  >
    <div class="co-container-md py-3 pl-5 pr-2 md:pb-0 md:pt-1">
      <a
        class="te-reskilling-banner-wrap flex w-full items-center justify-between gap-0 drop-shadow-sm sm:justify-center sm:gap-5 md:gap-10"
        href="/benefits/reskilling"
      >
        <img
          class="hidden lg:block"
          src="~/assets/images/Te/TeCommon/ReskillingBanner/human-object.png"
          width="156"
          height="155"
          loading="lazy"
        >
        <div class="flex w-full items-center justify-center gap-[18px] sm:w-auto sm:gap-4 md:w-auto">
          <div class="text-center">
            <div class="te-reskilling-sub-title inline-block border border-co-gray-50 px-2 py-3 font-bold text-co-gray-50 md:mb-3">
              リスキリングを通じた
              <br>
              キャリアアップ支援事業
            </div>
            <div class="co-display-11 hidden font-bold text-co-gray-50 md:block">補助制度を利用で</div>
          </div>
          <div class="text-center text-co-gray-50">
            <span class="te-reskilling-main-sub mb-1 inline-block px-2 py-0.5 text-xs font-bold md:text-base">期間限定 / 対象講座</span>
            <div class="flex">
              <div class="font-bold">
                <span class="te-reskilling-num leading-none">70</span>
                <span class="te-reskilling-yen mr-1 text-base md:text-xl">%</span>
                <span class="te-reskilling-num leading-none">56</span>
              </div>
              <div class="flex flex-col justify-end">
                <span class="te-reskilling-coution block scale-90 font-normal leading-none md:scale-100">※税抜額から</span>
                <div>
                  <span class="te-reskilling-yen text-base font-bold md:text-xl">万円</span>
                </div>
              </div>
            </div>
            <span class="te-reskilling-text-cashback font-bold">キャッシュバック</span>
          </div>
        </div>
        <div>
          <div class="inline sm:hidden">
            <i
              class="fa fa-chevron-right co-display-11 text-co-gray-50"
              arria-hidden="true"
            />
          </div>
          <div class="hidden sm:inline-block">
            <div class="co-btn-secondary-dark ">詳細はこちら
              <i
                class="fa fa-angle-right"
                arria-hidden="true"
              />
            </div>
          </div>
        </div>
      </a>
    </div>
  </section>
</template>

<style scoped>
.te-reskilling-banner {
  background: linear-gradient(180deg, #339aff 0%, #4dc2fb 100%);
}

@media (min-width: 768px) {
  .te-reskilling-banner {
    min-height: 10rem;
  }
}

.te-reskilling-banner-wrap {
  cursor: pointer;
}

.te-reskilling-sub-title {
  font-size: .75rem;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .te-reskilling-sub-title {
    font-size: 1.125rem;
  }
}

.te-reskilling-num {
    font-size: 2rem;
    color: #fff7b1;
  }

@media (min-width: 768px) {
  .te-reskilling-num {
    font-size: 4.25rem;
  }
}

.te-reskilling-yen {
  line-height: 1.2;
}

.te-reskilling-coution {
  font-size: 10px;
}

.te-reskillling-small-text {
  font-size: 0.625rem;
}

@media (min-width: 768px) {
  .te-reskillling-small-text {
    font-size: 0.75rem;
  }
}

.te-reskilling-text-cashback {
  font-size: 1.125rem;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .te-reskilling-text-cashback {
    font-size: 2.25rem;
  }
}

.te-reskilling-main-sub {
  background-color: #0971d4;
}

</style>
